import { Icon } from '@iconify/react';
import type { DefaultToastOptions } from 'react-hot-toast';

const defaultStyles =
  '!bg-neutral-100 !grid !grid-cols-[1fr_auto_1fr] !text-neutral-1000  content-center items-center !rounded-none !w-full  top-0 z-[9999999] left-0 font-inter font-semibold !max-w-none text-caption leading-[1.125rem]';

export const toastConfig: DefaultToastOptions = {
  className: `${defaultStyles}`,
  blank: {
    icon: (
      <Icon icon="material-symbols:info-outline" className="text-[1.25rem]" />
    ),
  },
  success: {
    icon: <Icon icon="charm:circle-tick" className="text-[1.25rem]" />,
  },
  error: {
    icon: <Icon icon="ph:smiley-sad-light" className="text-[1.25rem]" />,
    duration: Infinity,
  },
  loading: {
    icon: <Icon icon="tabler:loader" className="text-[1.25rem]" />,
  },
};
