import { createQueryKeys } from '@lukemorales/query-key-factory';
import type { PaginationState } from '@tanstack/react-table';
import { array, number, object } from 'yup';

import jobMatchingDetailFixture from '@/fixtures/jobMatching.json';
import jobMatchingListFixture from '@/fixtures/jobMatchingList.json';
import jobMatchingCandidatesFixture from '@/fixtures/jobMatchingResults.json';
import {
  candidateSchema,
  getJobMatchingCandidate,
  getJobMatchingCandidatesList,
  getJobMatchingOffer,
  getJobMatchingOfferList,
} from '@/services/jobmatching';

export const jobMatching = createQueryKeys('jobMatching', {
  list: (pagination: PaginationState, useMockData?: boolean) => ({
    queryFn: () =>
      useMockData
        ? jobMatchingListFixture
        : getJobMatchingOfferList(pagination),
    queryKey: [{ pagination, useMockData }],
  }),
  detail: (id: number, useMockData?: boolean) => ({
    queryFn: () =>
      useMockData ? jobMatchingDetailFixture : getJobMatchingOffer(id),
    queryKey: [id, useMockData],
    contextQueries: {
      candidatesList: (
        minScore?: number | null,
        shouldUseMockData?: boolean
      ) => ({
        queryFn: () =>
          shouldUseMockData
            ? object({
                candidates: array().of(candidateSchema).defined(),
                maxScore: number().required(),
              })
                .defined()
                .validateSync(jobMatchingCandidatesFixture)
            : getJobMatchingCandidatesList({ offerId: id, minScore }),
        queryKey: [{ minScore, shouldUseMockData }],
      }),
      candidate: (candidateId: number) => ({
        queryFn: () => getJobMatchingCandidate({ offerId: id, candidateId }),
        queryKey: [candidateId],
      }),
    },
  }),
});
