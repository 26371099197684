import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import classNames from 'classnames';
import type {
  ComponentPropsWithoutRef,
  CSSProperties,
  ElementType,
  FC,
  PropsWithChildren,
} from 'react';

const textStyles = cva('text-left', {
  variants: {
    variant: {
      'display-1': 'font-workSans text-display-3 md:text-display-1',
      'display-2': 'font-workSans text-h1 md:text-display-2',
      'display-3': 'font-workSans text-h2 md:text-display-3',
      h1: 'font-inter text-h1',
      'jb-h2': 'font-inter text-jb-h2',
      h2: 'font-inter text-h2',
      'jb-h3': 'font-inter text-jb-h3',
      h3: 'font-inter text-h3',
      h4: 'font-inter text-h4',
      h5: 'font-inter text-h5',
      'jb-body-large': 'font-inter text-jb-body-large',
      'jb-body-medium': 'font-inter text-jb-body-medium',
      'jb-body-small': 'font-inter text-jb-body-small',
      body: 'font-inter text-body',
      'body-small': 'font-inter text-body-small',
      'body-caption': 'font-inter text-caption',
      pricing: 'font-workSans text-pricing font-semibold',
      faq: 'font-inter text-faq',
      'jb-button-large': 'font-inter text-jb-button-large',
      'jb-button-medium': 'font-inter text-jb-button-medium',
      'jb-label-input': 'font-inter text-jb-label-input',
    },
  },
  defaultVariants: {
    variant: 'body',
  },
});

type Props = {
  as?: React.ElementType;
  className?: string;
  style?: CSSProperties;
  title?: string;
} & VariantProps<typeof textStyles> &
  ComponentPropsWithoutRef<'p'>;

const Text: FC<PropsWithChildren<Props>> = ({
  variant,
  className,
  as = 'p',
  children,
  style,
  title,
  ...props
}) => {
  const Elements: { [key: string]: ElementType } = {
    'display-1': 'h1',
    'display-2': 'h1',
    'display-3': 'h1',
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    body: 'p',
    'body-small': 'p',
    'body-caption': 'p',
  };
  const defaultElement: ElementType = Elements[variant ?? 'body'];

  const Element = as ?? defaultElement;
  return (
    <Element
      title={title}
      className={classNames(textStyles({ variant }), {
        [className!]: !!className,
        '[&>ol>li]:list-decimal [&>ol]:pl-4 [&>ul>li]:list-disc [&>ul]:pl-4 [&_h1]:text-4xl [&_h1]:uppercase [&_h2]:text-3xl [&_h2]:uppercase [&_h3]:text-2xl [&_h3]:uppercase [&_h4]:text-xl [&_h4]:uppercase':
          props.dangerouslySetInnerHTML,
      })}
      style={{ ...style }}
      {...props}
    >
      {children}
    </Element>
  );
};

export default Text;
