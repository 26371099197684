export const createMetaTags = ({
  description,
  title,
}: {
  title: string;
  description?: string;
}) => (
  <>
    <title>{title}</title>
    <meta key="og:title" property="og:title" content={title} />
    {description && (
      <meta key="description" name="description" content={description} />
    )}
    {description && (
      <meta
        key="og:description"
        property="og:description"
        content={description}
      />
    )}
  </>
);
