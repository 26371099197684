import axios from 'axios';
import QueryString from 'qs';
import { z } from 'zod';

import type { SeniorityLevel, WorkType } from '@/stores/useJobBoardStore';
import { SalaryPeriod } from '@/stores/useJobBoardStore';

import { DjangoDataZodSchema } from './common';
import { apiRoutes } from './config';

const seniorityLevelSchema = z.enum(['INTERN', 'JUNIOR', 'MID', 'SENIOR']);

const imageSchema = z
  .object({
    url: z.string(),
    width: z.number(),
    height: z.number(),
  })
  .nullable();

const externalOfferSchema = z.object({
  id: z.number(),
  jobId: z.string(),
  link: z.string(),
  companyName: z.string(),
  jobTitle: z.string(),
  location: z.string().nullable(),
  locations: z.array(z.string()),
  salaryLower: z.number().optional().nullable(),
  salaryUpper: z.number().optional().nullable(),
  salaryPeriod: z.nativeEnum(SalaryPeriod).nullable(),
  jobDescriptionText: z.string(),
  jobDescriptionTextWithStyling: z.string(),
  shortDescription: z.string().nullable().nullable(),
  requirements: z.array(z.string()),
  skills: z.array(z.string()),
  benefits: z.string().nullable(),
  isOnsite: z.boolean(),
  isHybrid: z.boolean(),
  isRemote: z.boolean(),
  seniorityLevel: seniorityLevelSchema,
  companyImage: imageSchema,
  createdAt: z.string(),
});

const externalOfferDetailSchema = externalOfferSchema.extend({
  companyDescription: z.string().nullable(),
});

export type SeniorityLevelTuple = z.infer<typeof seniorityLevelSchema>;

export type ExternalOffer = z.infer<typeof externalOfferSchema>;

const companySchema = z.object({
  name: z.string(),
  companyImage: imageSchema,
});

export const getExternalOffers = async ({
  limit,
  offset,
  search,
  location,
  workType,
  seniorityLevel,
  salaryLower,
  salaryUpper,
  ordering,
  companyName,
}: {
  offset: number;
  limit: number;
  search?: string;
  location?: string;
  workType?: WorkType[];
  seniorityLevel?: SeniorityLevel[];
  salaryLower?: string;
  salaryUpper?: string;
  ordering?: string;
  companyName?: string;
}) => {
  const { data } = await axios.get(
    apiRoutes['external-job-offers'].automation._root,
    {
      params: {
        offset,
        limit,
        search,
        location,
        work_type: workType,
        seniority_level: seniorityLevel,
        salary_lower: salaryLower,
        salary_upper: salaryUpper,
        ordering,
        company_name: companyName,
      },
      paramsSerializer: (params) => {
        return QueryString.stringify(params, { arrayFormat: 'repeat' });
      },
    }
  );
  return DjangoDataZodSchema(externalOfferSchema).parse(data);
};

export const getExternalOfferDetail = async (id: string) => {
  const { data } = await axios.get(
    apiRoutes['external-job-offers'].automation.detail(id)
  );
  return externalOfferDetailSchema.parse(data);
};

export const getSearchSuggestions = async (
  type: 'LOCATION' | 'JOB_TITLE',
  query: string
) => {
  const { data } = await axios.get(
    apiRoutes['external-job-offers'].automation.searchSuggestions,
    {
      params: {
        search_type: type,
        query,
      },
    }
  );
  return z.array(z.string()).parse(data);
};

export const getCompanies = async () => {
  const { data } = await axios.get(
    apiRoutes['external-job-offers'].automation.companies
  );

  return z.array(companySchema).parse(data);
};
