import type { InferType } from 'yup';
import { array, boolean, date, mixed, number, object, string } from 'yup';

import authService from '@/services/auth/auth';
import { capitalizeFirstLetter } from '@/utils/cvGenerator';

import { DjangoDataSchema } from './common';
import { apiRoutes } from './config';

export enum SkillTimeExperienceFormatOptions {
  YEAR_ROUND = 'YEAR_ROUND',
  YEAR_ROUND_UP = 'YEAR_ROUND_UP',
  YEAR_ROUND_DOWN = 'YEAR_ROUND_DOWN',
  MONTH = 'MONTH',
  YEAR_MONTH = 'YEAR_MONTH',
  FLOAT_YEAR = 'FLOAT_YEAR',
}

export enum SumTimeExperienceFormatOptions {
  YEAR_ROUND = 'YEAR_ROUND',
  YEAR_ROUND_UP = 'YEAR_ROUND_UP',
  YEAR_ROUND_DOWN = 'YEAR_ROUND_DOWN',
  MONTH = 'MONTH',
  YEAR_MONTH = 'YEAR_MONTH',
}

export enum DateFormatOptions {
  MONTH_YEAR_DOT = 'MONTH_YEAR_DOT',
  MONTH_YEAR = 'MONTH_YEAR',
  YEAR = 'YEAR',
  MONTH_NAME_YEAR = 'MONTH_NAME_YEAR',
  MONTH_SHORT_NAME_YEAR = 'MONTH_SHORT_NAME_YEAR',
  SOURCE = 'SOURCE',
  YEAR_MONTH_DOT = 'YEAR_MONTH_DOT',
  YEAR_MONTH = 'YEAR_MONTH',
}

export enum LanguageLevelPredefinedOptions {
  CEFR = 'CEFR',
  NUMERIC = 'NUMERIC',
  ADJECTIVE = 'ADJECTIVE',
  CUSTOM = 'CUSTOM',
}

export enum TranslateLanguages {
  ENGLISH = 'ENGLISH',
  POLISH = 'POLISH',
  SPANISH = 'SPANISH',
  RUSSIAN = 'RUSSIAN',
  FRENCH = 'FRENCH',
  GERMAN = 'GERMAN',
  ITALIAN = 'ITALIAN',
  PORTUGUESE = 'PORTUGUESE',
  UKRAINIAN = 'UKRAINIAN',
}

export const translateLanguagesOptions = Object.values(TranslateLanguages).map(
  (language) => ({
    value: language,
    label:
      language === 'ENGLISH'
        ? `${capitalizeFirstLetter(language)} (Default)`
        : capitalizeFirstLetter(language),
  })
);

const SettingsSchema = object({
  dateFormat: string()
    .nullable()
    .defined()
    .oneOf([...Object.values(DateFormatOptions), null, '']),
  sumTimeExperienceFormat: string()
    .nullable()
    .defined()
    .oneOf([...Object.values(SumTimeExperienceFormatOptions), null, '']),
  languageLevelPredefined: string()
    .nullable()
    .defined()
    .oneOf([...Object.values(LanguageLevelPredefinedOptions), null, '']),
  languageLevelCustom: object({
    basic: string(),
    native: string(),
    advanced: string(),
    nearNative: string(),
    intermediate: string(),
  }),
  preferredCvLanguage: string()
    .defined()
    .oneOf([...Object.values(TranslateLanguages)]),
  skillTimeExperienceFormat: string()
    .nullable()
    .defined()
    .oneOf([...Object.values(SkillTimeExperienceFormatOptions), null, '']),
  hideSkillsYearsOfExp: boolean().required(),
  groupSkills: boolean().required(),
  useAbbreviationsInSkills: boolean().required(),
  defaultTemplate: string().defined(),
  logo: string().url().nullable().defined(),
  skillsLevel: object({}),
});
export type Settings = InferType<typeof SettingsSchema>;

const BasicTemplateSchema = object({
  companyName: string().defined(),
  email: string().email().defined(),
  phone: string().defined(),
  notes: string(),
  fontName: string(),
  title: string(),
  header1: string(),
  header2: string(),
  text: string(),
  caption: string(),
  sections: array().of(string().defined()).required(),
  extraSections: string(),
});

const SendToAPIRequestTemplateSchema = object({
  templateFiles: array().of(mixed<File | Blob>().required()).required(),
  logoFile: mixed<File | Blob>().nullable().defined(),
  customFontFile: mixed<File | Blob>().nullable().defined(),
}).concat(BasicTemplateSchema);

const GetFromAPIRequestTemplateSchema = object({
  id: number().defined(),
  updatedAt: date().defined(),
  createdAt: date().defined(),
  templateFiles: array().of(string().defined()).required(),
  logoFile: string().nullable().defined(),
  customFontFile: string().nullable().defined(),
}).concat(BasicTemplateSchema);

export type SendToAPIRequestTemplate = InferType<
  typeof SendToAPIRequestTemplateSchema
>;
export type GetFromAPIRequestTemplate = InferType<
  typeof GetFromAPIRequestTemplateSchema
>;

export const getSettings = async () => {
  const { data } = await authService.get(apiRoutes.settings.recruiter.mine);

  return SettingsSchema.validate({
    ...data,
    preferredCvLanguage: data.preferredCvLanguage ?? TranslateLanguages.ENGLISH,
  });
};

export const patchSettings = async (patchData: Partial<Settings>) => {
  const { data } = await authService.patch(
    apiRoutes.settings.recruiter.mine,
    patchData
  );
  return SettingsSchema.validate(data);
};

export const postTemplateRequest = async (data: SendToAPIRequestTemplate) => {
  const { data: responseData } = await authService.post(
    apiRoutes.settings.templateRequest._root,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return GetFromAPIRequestTemplateSchema.validate(responseData);
};

export const patchTemplateRequest = async ({
  id,
  data,
}: {
  id: number;
  data: SendToAPIRequestTemplate;
}) => {
  const { data: responseData } = await authService.patch(
    apiRoutes.settings.templateRequest.id(id),
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return GetFromAPIRequestTemplateSchema.validate(responseData);
};

export const getTemplateRequests = async () => {
  const { data } = await authService.get(
    apiRoutes.settings.templateRequest._root
  );
  return DjangoDataSchema(GetFromAPIRequestTemplateSchema).validate(data);
};

export const deleteTemplateRequest = async (id: number) =>
  authService.delete(apiRoutes.settings.templateRequest.id(id));
