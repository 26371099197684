import axios from 'axios';
import QueryString from 'qs';
import { z } from 'zod';

import authService from './auth/auth';
import { DjangoDataZodSchema } from './common';
import { apiRoutes } from './config';

export enum PostsOrderingEnum {
  CREATED_AT = 'created_at',
  CREATED_AT_DESC = '-created_at',
  LIKES_COUNT = 'likes_count',
  LIKES_COUNT_DESC = '-likes_count',
  COMMENTS_COUNT = 'comments_count',
  COMMENTS_COUNT_DESC = '-comments_count',
}

export enum TagsEnum {
  COMPANY = 'COMPANY',
  TOPIC = 'TOPIC',
}

const communityTagSchema = z.object({
  id: z.number(),
  name: z.string(),
  tagType: z.nativeEnum(TagsEnum),
  company: z
    .object({
      name: z.string(),
      image: z.string(),
      link: z.string(),
    })
    .nullable(),
});
const profileSchema = z.object({
  username: z.string(),
  fullName: z.string().nullable(),
  jobTitle: z.string(),
  companyName: z.string().nullable(),
  displayFullName: z.boolean(),
  displayJobTitle: z.boolean(),
  displayCompanyName: z.boolean(),
  likesCount: z.number(),
  postsCount: z.number(),
});

const authorSchema = z.object({
  companyName: z.string().nullable(),
  fullName: z.string().nullable(),
  jobTitle: z.string().nullable(),
  likesCount: z.number().nullable(),
  postsCount: z.number().nullable(),
  username: z.string().nullable(),
});

const baseCommentSchema = z.object({
  author: authorSchema,
  content: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  hasLiked: z.boolean(),
  id: z.number(),
  parent: z.number().nullable(),
  post: z.number(),
  repliesCount: z.number(),
  likesCount: z.number(),
});

const commentSchema = baseCommentSchema.extend({
  replies: baseCommentSchema.array(),
});

export type Comment = z.infer<typeof commentSchema>;

const postSchema = z.object({
  id: z.number(),
  author: authorSchema,
  title: z.string(),
  content: z.string(),
  likesCount: z.number(),
  commentsCount: z.number(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  hasLiked: z.boolean(),
  tags: z.array(communityTagSchema),
  comments: z.array(commentSchema),
});

const DjangoPostSchema = DjangoDataZodSchema(postSchema);
export type Post = z.infer<typeof postSchema>;
export type DjangoPost = z.infer<typeof DjangoPostSchema>;
type CommentParamsPost = {
  postId: number;
  commentId: number;
};
export const getPosts = async ({
  offset,
  pageSize,
  tags,
  query,
  ordering,
}: {
  offset?: number;
  pageSize?: number;
  ordering?: PostsOrderingEnum;
  tags?: number[];
  query?: string;
}) => {
  const { data } = await authService.get(
    apiRoutes.candidateCommunity.posts._root,
    {
      params: {
        offset,
        limit: pageSize,
        tags,
        query,
        ordering: ordering ?? PostsOrderingEnum.CREATED_AT_DESC,
      },
      paramsSerializer: (params) => {
        return QueryString.stringify(params, { arrayFormat: 'repeat' });
      },
    }
  );
  return DjangoPostSchema.parse(data);
};

export const getPostDetail = async ({ id }: { id: number }) => {
  const { data } = await authService.get(
    apiRoutes.candidateCommunity.posts.detail(id)._root
  );
  return postSchema.parse(data);
};

export const getCommunityProfile = async () => {
  const { data } = await authService.get(
    apiRoutes.candidateCommunity.profile.me
  );
  return profileSchema.parse(data);
};

export const postLikePost = (id: number) => {
  return authService.post(apiRoutes.candidateCommunity.posts.detail(id).like);
};

export const postUnlikePost = (id: number) => {
  return authService.post(apiRoutes.candidateCommunity.posts.detail(id).unlike);
};
export const postLikeComment = ({ postId, commentId }: CommentParamsPost) => {
  return authService.post(
    apiRoutes.candidateCommunity.posts.detail(postId).comments.detail(commentId)
      .like
  );
};

export const postUnlikeComment = ({ postId, commentId }: CommentParamsPost) => {
  return authService.post(
    apiRoutes.candidateCommunity.posts.detail(postId).comments.detail(commentId)
      .unlike
  );
};

export const postCommunityPost = async (formData: {
  title: string;
  content: string;
  tags: number[];
}) => {
  const { data } = await authService.post(
    apiRoutes.candidateCommunity.posts._root,
    formData
  );
  return postSchema.parse(data);
};

export const postComment = async ({
  post,
  ...formData
}: {
  post: number;
  content: string;
  parent: number | null;
}) => {
  return authService.post(
    apiRoutes.candidateCommunity.posts.detail(post).comments._root,
    formData
  );
};

export type CommunityTag = z.infer<typeof communityTagSchema>;

export const getCommunityTags = async () => {
  const { data } = await axios.get(apiRoutes.candidateCommunity.posts.tags);
  return z.array(communityTagSchema).parse(data);
};

export const getHotTopics = async () => {
  const { data } = await authService.get(
    apiRoutes.candidateCommunity.hotTopics
  );
  return z.array(postSchema).parse(data);
};
