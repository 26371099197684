import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getProfileData } from '@/services/profile';

export const profile = createQueryKeys('profile', {
  me: {
    queryKey: null,
    queryFn: getProfileData,
  },
});
