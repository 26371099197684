import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDown } from 'lucide-react';
import * as React from 'react';

import { cn } from '@/lib/utils';

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn('border-b', className)}
    {...props}
  />
));
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
    headerClassName?: string;
  }
>(({ className, children, headerClassName, ...props }, ref) => (
  <AccordionPrimitive.Header className={cn('flex', headerClassName)}>
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'flex flex-1 items-center justify-between py-4 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180',
        className
      )}
      {...props}
    >
      {children}
      <ChevronDown className="h-4 w-4 shrink-0 transition-transform duration-200" />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content> & {
    contentWrapClassName?: string;
  }
>(({ className, contentWrapClassName, children, ...props }, ref) => {
  const [isOverflowVisable, setIsOverflowVisable] = React.useState(true);
  return (
    <AccordionPrimitive.Content
      ref={ref}
      className={cn(
        'overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
        className,
        { 'overflow-visible': isOverflowVisable }
      )}
      // data[state=open]:overflow-visible has a bug when a user opens an accordion
      // the content is visible before the animation ends.
      // The solution below help take care for UX
      onAnimationEnd={(element) =>
        element.animationName === 'accordion-up'
          ? setIsOverflowVisable(false)
          : setIsOverflowVisable(true)
      }
      onAnimationStart={() => setIsOverflowVisable(false)}
      {...props}
    >
      <div
        className={cn('pb-4 pt-0', {
          [`${contentWrapClassName}`]: !!contentWrapClassName,
        })}
      >
        {children}
      </div>
    </AccordionPrimitive.Content>
  );
});
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionContent, AccordionItem, AccordionTrigger };
