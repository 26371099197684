/* eslint-disable no-param-reassign */
import type { AxiosRequestTransformer } from 'axios';
import baseAxiosInstance from 'axios';

export const axios = baseAxiosInstance.create();

const transformValuesInFormDataRequest = (value: unknown) => {
  if (value === null) return 'null';
  if (JSON.stringify(value) === JSON.stringify([])) return 'empty_array';
  return value;
};
const transformNullInFormData: AxiosRequestTransformer = (data, headers) => {
  if (
    headers['Content-Type'] === 'multipart/form-data' &&
    typeof data === 'object'
  ) {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        transformValuesInFormDataRequest(value),
      ])
    );
  }
  return data;
};

axios.interceptors.request.use((config) => {
  if (axios.defaults.transformRequest)
    config.transformRequest = [transformNullInFormData].concat(
      axios.defaults.transformRequest
    );
  else {
    config.transformRequest = [transformNullInFormData];
  }
  config.formSerializer = { indexes: true, ...axios.defaults.formSerializer };
  return config;
});
