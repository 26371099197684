export const getNumberParams = (param: string | string[] | undefined) => {
  if (typeof param === 'string')
    return Number.isNaN(parseInt(param, 10)) ? undefined : parseInt(param, 10);
  return undefined;
};

export const getArrayNumberParams = (param: string | string[] | undefined) => {
  if (Array.isArray(param)) {
    return param
      .map((singleParam) => parseInt(singleParam, 10))
      .filter((parsedParam) => !Number.isNaN(parsedParam));
  }
  if (typeof param === 'string') {
    return Number.isNaN(parseInt(param, 10)) ? [] : [parseInt(param, 10)];
  }
  return [];
};

export const getArrayParams = (param: string | string[] | undefined) => {
  if (Array.isArray(param)) return param;
  if (typeof param === 'string' && param) return [param];
  return [];
};

export const getStringParam = (param: string | string[] | undefined) => {
  if (typeof param === 'string' && param) return param;
  return undefined;
};

export const getBooleanParam = (param: string | string[] | undefined) => {
  if (param === 'true') return true;
  if (param === 'false') return false;
};
