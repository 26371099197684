import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getSettings, getTemplateRequests } from '@/services/settings';

export const settings = createQueryKeys('settings', {
  mine: {
    queryKey: null,
    queryFn: getSettings,
  },
  templateRequest: {
    queryKey: null,
    contextQueries: {
      list: {
        queryKey: null,
        queryFn: getTemplateRequests,
      },
    },
  },
});
