export const formatExperience = (
  experience: number | null,
  defaultText?: string
) => {
  if (experience === null || experience === 0) return defaultText ?? '-';
  const years = Math.floor(experience);
  const months = Math.round((experience - years) * 12);

  let result = '';

  if (years > 0) {
    result += years === 1 ? '1 year' : `${years} years`;
  }

  if (months > 0) {
    if (result !== '') {
      result += ' ';
    }
    result += months === 1 ? '1 month' : `${months} months`;
  }

  return result;
};

export const capitalizeFirstLetter = (inputString: string) => {
  return (
    inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
  );
};
