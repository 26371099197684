import axios from 'axios';
import type { InferType } from 'yup';
import { array, boolean, number, object, string } from 'yup';

import authService from '@/services/auth/auth';

import { apiRoutes } from './config';

const PriceSchema = object({
  order: number().required(),
  id: number().required(),
  isTrial: boolean().required(),
  interval: string().defined(),
  name: string().defined(),
  description: string().defined(),
  amount: number().nullable().defined(),
  currency: string().defined(),
  discount: number().nullable().defined(),
  features: array().of(string().defined()).defined(),
}).required();
export type Price = InferType<typeof PriceSchema> & {
  waitlist?: {
    buttonText: string;
    waitlistName: string;
  };
};

const PackagesSchema = object({
  prices: array().of(number().defined()).required(),
  name: string().defined(),
  active: boolean().defined(),
  description: string().defined(),
  createdAt: string().defined(),
  updatedAt: string().defined(),
});

const redirectResponse = object({ url: string().defined() }).required();
export const getCustomerPortal = async () => {
  const { data } = await authService.get(
    apiRoutes.payments.stripe.customerPortal
  );
  const validatedData = await redirectResponse.validate(data);
  window.location.href = validatedData.url;
  return validatedData;
};

export const getProducts = async () => {
  const { data } = await axios.get(apiRoutes.payments.stripe.prices);
  return array().of(PriceSchema).required().validate(data);
};

export const postCheckout = async (id: number) => {
  const { data } = await authService.post(
    apiRoutes.payments.stripe.checkout(id)
  );
  const validatedData = await redirectResponse.validate(data);
  window.location.href = validatedData.url;
};

export const getPackages = async () => {
  const { data } = await axios.get(apiRoutes.payments.stripe.packages, {
    params: { offset: 0, limit: 999 },
  });
  return array().of(PackagesSchema).validate(data);
};

export const getUserCurrentSubscriptionData = async () => {
  const { data } = await authService.get(
    apiRoutes.payments.stripe.userCurrentBoughtPrice,
    {
      params: { offset: 0, limit: 999 },
    }
  );
  if (!data) return null;
  return PriceSchema.validate(data);
};

export const getTrialStatusData = async () => {
  const { data } = await authService.get(
    apiRoutes.payments.stripe.hasUserUsedTrial,
    { params: { offset: 0, limit: 999 } }
  );

  return object({
    wasTrialUsed: boolean().defined(),
  })
    .required()
    .validate(data);
};
