import { useRouter } from 'next/router';
import type { ParsedUrlQuery } from 'querystring';
import { z } from 'zod';

import { asArray } from '@/utils/asArray';

export enum SeniorityLevel {
  INTERN = 'INTERN',
  JUNIOR = 'JUNIOR',
  MID = 'MID',
  SENIOR = 'SENIOR',
}

export enum WorkType {
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME',
  INTERNSHIP = 'INTERNSHIP',
  FREELANCER = 'FREELANCER',
  CONSULTANT = 'CONSULTANT',
}

export enum Industry {
  TECH = 'TECH',
  ENGINEERING = 'ENGINEERING',
  DESIGN = 'DESIGN',
  IT = 'IT',
  AI = 'AI',
  CYBERSECURITY = 'CYBERSECURITY',
  DATA = 'DATA',
  CLOUD = 'CLOUD',
  SOFTWARE = 'SOFTWARE',
  FINTECH = 'FINTECH',
  HEALTH = 'HEALTH',
  EDTECH = 'EDTECH',
  E_COMMERCE = 'E_COMMERCE',
  ROBOTICS = 'ROBOTICS',
}

export enum Ordering {
  CREATED_AT = 'created_at',
  '-CREATED_AT' = '-created_at',
  SALARY_LOWER = 'salary_lower',
  '-SALARY_LOWER' = '-salary_lower',
}

export const orderingOptions = [
  { label: 'Newest Post', value: Ordering['-CREATED_AT'] },
  { label: 'Oldest Post', value: Ordering.CREATED_AT },
  { label: 'Highest Salary', value: Ordering['-SALARY_LOWER'] },
  { label: 'Lowest Salary', value: Ordering.SALARY_LOWER },
];

export enum SalaryPeriod {
  HOUR = 'HOUR',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

const paramsSchema = z.object({
  search: z.string().optional().catch(undefined),
  location: z.string().optional().catch(undefined),
  ordering: z.nativeEnum(Ordering).optional().catch(undefined),
  seniorityLevel: asArray(z.nativeEnum(SeniorityLevel))
    .optional()
    .catch(undefined),
  workType: asArray(z.nativeEnum(WorkType)).optional().catch(undefined),
  industry: asArray(z.nativeEnum(Industry)).optional().catch(undefined),
  company: asArray(z.string()).optional().catch(undefined),
  company_name: z.string().optional().catch(undefined),
  salaryLower: z.coerce.number().optional().catch(undefined),
  salaryUpper: z.coerce.number().optional().catch(undefined),
});

export type DashboardParams = z.infer<typeof paramsSchema>;

const getQueryParams = (query: ParsedUrlQuery) => paramsSchema.parse(query);

export const useJobBoard = () => {
  const router = useRouter();
  const params = getQueryParams(router.query);

  const setParams = (newParams: Partial<DashboardParams>) => {
    if (!router.isReady) return;
    router.replace({
      query: {
        ...router.query,
        ...newParams,
      },
    });
  };

  const deleteParam = (
    key: string,
    value: DashboardParams[keyof DashboardParams]
  ) => {
    const { [key]: valuesToFiltrate, ...newParams } = router.query;
    if (!valuesToFiltrate) return;
    if (Array.isArray(valuesToFiltrate)) {
      const newFiltredValues = valuesToFiltrate.filter(
        (singleValue) => singleValue !== value
      );
      return router.replace({
        query: {
          [key]: newFiltredValues,
          ...newParams,
        },
      });
    }
    if (valuesToFiltrate === value) {
      return router.replace({
        query: newParams,
      });
    }
  };

  const resetParams = () => {
    if (!router.isReady) return;
    router.replace({ query: undefined });
  };
  return {
    setParams,
    resetParams,
    deleteParam,
    params: {
      ...params,
      ordering: orderingOptions.find(
        (option) =>
          option.value === (params.ordering ?? Ordering['-CREATED_AT'])
      ),
    },
  };
};
