/* eslint-disable no-param-reassign */

import Cookies from 'universal-cookie';
import { object, string } from 'yup';

import type { Register } from '@/pages/dashboard/auth/signup';

import { axios } from '../axios';
import { apiRoutes } from '../config';

axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  (config) => {
    const cookies = new Cookies();
    config.headers['X-CSRFToken'] = cookies.get('csrftoken');
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err?.response?.config;
    if (
      err?.response?.status === 401 &&
      !originalConfig._retry &&
      originalConfig?.url !== apiRoutes.auth.token.refresh
    ) {
      originalConfig._retry = true;
      try {
        await axios.post(apiRoutes.auth.token.refresh);
        return await axios(originalConfig);
      } catch (_error) {
        return Promise.reject(err);
      }
    }

    return Promise.reject(err);
  }
);

export enum EnumAccountType {
  CANDIDATE = 'CANDIDATE',
  CUSTOMER = 'CUSTOMER',
}

export const login = (data: {
  email: string;
  password: string;
  rememberMe?: boolean | undefined;
}) => axios.post(apiRoutes.auth.token._root, data);

export const register = (data: Register) =>
  axios.post(apiRoutes.auth.customerRegister, data);

export const candidateRegister = (data: {
  name: string;
  email: string;
  password: string;
}) => axios.post(apiRoutes.auth.register, data);

export const requestAccountActivate = (email: string) =>
  axios.post(apiRoutes.auth.activateRequest, { email });

export const resetPasswordRequest = (email: string) =>
  axios.post(apiRoutes.auth.resetPasswordRequest, {
    email,
  });

export const activateAccount = (token: string) =>
  axios.post(apiRoutes.auth.activate, {
    token,
  });

export const postActiveCandidateStatusRequest = (data: {
  token: string;
  userChoice: boolean;
}) => axios.post(apiRoutes.auth.activeCandidateStatusRequest, data);

export const getGoogleAuth = async (accountType: EnumAccountType) => {
  const { data } = await axios.get(apiRoutes.auth.google._root, {
    params: { accountType },
    withCredentials: true,
  });
  const validData = object({
    authorizationUrl: string().url().defined(),
  }).validate(data);
  return validData;
};

export const postGoogleOneTap = ({
  credential,
  accountType,
}: {
  credential: string;
  accountType: EnumAccountType;
}) =>
  axios.post(apiRoutes.auth.google.oneTap, {
    token: credential,
    accountType,
  });

export const resetPassword = (formData: {
  token: string;
  password: string;
  passwordRepeated: string;
}) => axios.post(apiRoutes.auth.resetPassword, formData);

export default axios;
