import { createQueryKeys } from '@lukemorales/query-key-factory';

import {
  getCustomerPortal,
  getPackages,
  getProducts,
  getTrialStatusData,
  getUserCurrentSubscriptionData,
} from '@/services/payments';

export const payments = createQueryKeys('payments', {
  products: {
    queryKey: null,
    queryFn: getProducts,
  },
  customerPortal: {
    queryKey: null,
    queryFn: getCustomerPortal,
  },
  packages: {
    queryKey: null,
    queryFn: getPackages,
  },
  getUserCurrentSubscriptionData: {
    queryKey: null,
    queryFn: getUserCurrentSubscriptionData,
  },
  hasUserUsedTrial: {
    queryKey: null,
    queryFn: getTrialStatusData,
  },
});
