import type { PaginationState, SortingState } from '@tanstack/react-table';
import type { InferType } from 'yup';
import { array, boolean, number, object, string } from 'yup';

import authService from '@/services/auth/auth';

import type { DjangoData } from './common';
import { DjangoDataSchema } from './common';
import { apiRoutes } from './config';

export enum Status {
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
  FINISHED = 'FINISHED',
}

export enum Decision {
  YES = 'YES',
  UNKNOWN = 'UNKNOWN',
  NO = 'NO',
}

const offerListSchema = object({
  id: number().required(),
  title: string().defined(),
  text: string().defined(),
});
export type OffersListType = DjangoData<InferType<typeof offerListSchema>>;

const requirementSchema = object({
  id: number().required(),
  text: string().defined(),
  weight: number().required(),
}).defined();

const aiOfferSchema = object({
  title: string().defined(),
  text: string().defined(),
  requirements: array()
    .of(requirementSchema.omit(['id']))
    .defined(),
});
export type AiOfferSchema = InferType<typeof aiOfferSchema>;

export const candidateSchema = object({
  id: number().required(),
  data: object({
    id: number().required(),
    contextSourceName: string().nullable(),
    name: string().defined().nullable(),
    publicId: string().nullable(),
  }).defined(),
  characteristics: object({
    status: string<Status>().oneOf(Object.values(Status)).defined(),
    strengths: array().of(string().defined()).defined(),
    weaknesses: array().of(string().defined()).defined(),
  })
    .nullable()
    .defined(),
  analyzedRequirements: array()
    .of(
      object({
        requirement: requirementSchema,
        status: string<Status>().oneOf(Object.values(Status)).defined(),
        decision: string<Decision>().oneOf(Object.values(Decision)).defined(),
        justification: string().defined(),
      }).defined()
    )
    .defined(),
  score: number().required(),
});
export type JobMatchingCandidate = InferType<typeof candidateSchema>;

const offerSchema = object({
  requirements: array().of(requirementSchema).defined(),
  maxScore: number().required(),
  candidates: array()
    .of(
      object({
        anyRequirementsProcessing: boolean(),
        id: number().required(),
        data: object({
          id: number().required(),
          name: string().defined().nullable(),
          contextSourceName: string().nullable(),
          publicId: string().nullable(),
        }),
        score: number(),
      }).defined()
    )
    .defined(),
}).concat(offerListSchema);

export type OfferType = InferType<typeof offerSchema>;

export const getJobMatchingOfferList = async ({
  pageIndex,
  pageSize,
}: PaginationState) => {
  const { data } = await authService.get(apiRoutes.jobMatching.offer._root, {
    params: {
      limit: pageSize,
      offset: pageSize * pageIndex,
    },
  });
  return DjangoDataSchema(offerListSchema).validate(data);
};

export const getJobMatchingOffer = async (id: number) => {
  const { data } = await authService.get(
    apiRoutes.jobMatching.offer.detail(id)._root
  );
  return offerSchema.validate(data);
};

export const postAiOfferPost = async (text: string) => {
  const { data } = await authService.post(
    apiRoutes.jobMatching.offer.generateAiOfferDetails,
    {
      text,
    }
  );
  return aiOfferSchema.validate(data);
};

export const postJobMatchingOffer = async ({
  offer,
  applications,
  externalCandidates,
}: {
  offer: string;
  applications?: number[];
  externalCandidates?: number[];
}) => {
  const newOffer = await postAiOfferPost(offer);
  const { data } = await authService.post(apiRoutes.jobMatching.offer._root, {
    ...newOffer,
    externalCandidates: { ids: externalCandidates ?? [] },
    applications: { ids: applications ?? [] },
  });
  return offerSchema.validate(data);
};

export const patchJobMatchingOffer = async ({
  id,
  requirements,
}: {
  id: number;
  requirements: (Omit<OfferType['requirements'][number], 'id'> & {
    id?: number;
  })[];
}) => {
  const { data } = await authService.patch(
    apiRoutes.jobMatching.offer.detail(id)._root,
    {
      requirements,
    }
  );
  return offerSchema.validate(data);
};

export const postCvsToJobMatchingOffer = async ({
  cvIds,
  offerId,
}: {
  offerId: number;
  cvIds: number[];
}) => {
  return authService.post(
    apiRoutes.jobMatching.offer.detail(offerId).add.fromExternalCandidates,
    { ids: cvIds }
  );
};

export const deleteJobMatchingOffer = async ({
  offerId,
}: {
  offerId: number;
}) => {
  return authService.delete(apiRoutes.jobMatching.offer.detail(offerId)._root);
};

export const getJobMatchingCandidatesList = async ({
  offerId,
  order,
  minScore,
}: {
  offerId: number;
  order?: SortingState;
  minScore?: number | null;
}) => {
  const { data } = await authService.get(
    apiRoutes.jobMatching.offer.detail(offerId).candidates._root,
    {
      params: {
        order: order?.map(
          ({ desc, id: columnId }) => `${desc ? '-' : ''}${columnId}`
        ),
        min_score: minScore ?? undefined,
      },
    }
  );
  return object({
    candidates: array().of(candidateSchema).defined(),
    maxScore: number().required(),
  })
    .defined()
    .validate(data);
};

export const getJobMatchingCandidate = async ({
  offerId,
  candidateId,
}: {
  offerId: number;
  candidateId: number;
}) => {
  const { data } = await authService.get(
    apiRoutes.jobMatching.offer
      .detail(offerId)
      .candidates.candidate(candidateId)
  );
  return candidateSchema.validate(data);
};

export const deleteJobMatchingCandidate = async ({
  offerId,
  candidateId,
}: {
  offerId: number;
  candidateId: number;
}) =>
  authService.delete(
    apiRoutes.jobMatching.offer
      .detail(offerId)
      .candidates.candidate(candidateId)
  );

export const postJobMatchingCandidateRetry = async ({
  offerId,
  candidateId,
}: {
  offerId: number;
  candidateId: number;
}) => {
  return authService.post(
    apiRoutes.jobMatching.offer.detail(offerId).retry.candidate(candidateId)
  );
};
