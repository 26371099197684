import classNames from 'classnames';

import Text from './Text';
import { Accordion, AccordionContent, AccordionItem } from './ui/accordion';

const ErrorAccordion = ({
  error,
  className,
  errorTextClassName,
}: {
  error?: string;
  className?: string;
  errorTextClassName?: string;
}) => {
  return (
    <div className={classNames(className)}>
      <Accordion type="single" value={error ? 'show' : undefined}>
        <AccordionItem value="show" className="border-0">
          <AccordionContent contentWrapClassName="pb-0">
            <Text
              variant="body-small"
              className={classNames('leading-5 text-error', errorTextClassName)}
            >
              {error}
            </Text>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default ErrorAccordion;
