import { z } from 'zod';

import authService from '@/services/auth/auth';

import { DjangoDataZodSchema } from './common';
import { apiRoutes } from './config';

const changelogSchema = z.object({
  id: z.number(),
  title: z.string(),
  description: z.string(),
  createdAt: z.string(),
  userSeen: z.boolean(),
  img: z.string(),
});
export type Changelog = z.infer<typeof changelogSchema>;

export const getChangelogs = async () => {
  const { data } = await authService.get(apiRoutes.changelog._root);
  return DjangoDataZodSchema(changelogSchema).parse(data);
};

export const getChangelog = async (id: number) => {
  const { data } = await authService.get(apiRoutes.changelog.detail(id)._root);
  return changelogSchema.parse(data);
};

export const postChangelogSeen = async (id: number) =>
  authService.post(apiRoutes.changelog.detail(id).seen);
