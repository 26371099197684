import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getChangelog, getChangelogs } from '@/services/changelog';

export const changelog = createQueryKeys('changelog', {
  list: {
    queryKey: null,
    queryFn: getChangelogs,
  },
  detail: (id: number) => ({
    queryKey: [id],
    queryFn: () => getChangelog(id),
  }),
});
