import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import type { UseQueryFactoryProps } from '@/queries';
import { queries } from '@/queries';

export const useProfile = (
  props?: UseQueryFactoryProps<typeof queries.profile.me>
) => {
  const queryClient = useQueryClient();
  const res = useQuery({
    ...queries.profile.me,
    meta: { silent: true },
    onError: (err) => {
      if (
        err instanceof AxiosError &&
        err.response?.status === 401 &&
        queryClient.getQueryState(queries.profile.me.queryKey)?.data
      ) {
        queryClient.resetQueries({ queryKey: queries.profile.me.queryKey });
      }
    },
    staleTime: 1000 * 60 * 10,
    retry: (count, err) => {
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          return false;
        }
      }
      return typeof props?.retry === 'number' ? count < props.retry : count < 3;
    },
    ...props,
  });

  return res;
};
