import * as Sentry from '@sentry/nextjs';
import { decode } from 'js-base64';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { getStringParam } from '@/utils/queryParams';

export const useSentryEmailParam = () => {
  const router = useRouter();
  useEffect(() => {
    if (!router.isReady) return;

    const { uid: email } = router.query;

    if (email && typeof email === 'string' && email.length > 0) {
      try {
        const emailParam = getStringParam(email) ?? '';
        const decodedEmail = decode(emailParam);
        Sentry.setUser({ email: decodedEmail });
      } catch (_e) {
        /* empty */
      } finally {
        router.replace({ query: { ...router.query, uid: undefined } });
      }
    }
  }, [router.isReady, router.query?.email]);
};
