import { createQueryKeys } from '@lukemorales/query-key-factory';

import {
  getCities,
  getCountries,
  getLanguages,
  getSkills,
} from '@/services/common';

export const common = createQueryKeys('common', {
  languages: {
    queryKey: null,
    queryFn: getLanguages,
  },
  skills: {
    queryKey: null,
    queryFn: getSkills,
  },
  cities: (country: Parameters<typeof getCities>[0]) => ({
    queryKey: [{ country }],
    queryFn: () => getCities(country),
  }),
  countries: (continent: Parameters<typeof getCountries>[0]) => ({
    queryKey: [{ continent }],
    queryFn: () => getCountries(continent),
  }),
});
