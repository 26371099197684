import '@/styles/globals.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import '@/styles/phoneInputStyles.css';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { Inter, Work_Sans } from 'next/font/google';
import Head from 'next/head';
import type { ReactElement, ReactNode } from 'react';
import ReactGA from 'react-ga4';
import toast, { ToastBar, Toaster } from 'react-hot-toast';

import AuthOnlyPage from '@/components/AuthOnlyPage';
import queryClient from '@/config/queryClient';
import { toastConfig } from '@/config/toastConfig';
import { useSentryEmailParam } from '@/hooks/useSentryEmailParam';
import { createMetaTags } from '@/utils/seo';

const inter = Inter({
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-inter',
});
const workSans = Work_Sans({
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-work-sans',
});

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  if (GA_MEASUREMENT_ID) ReactGA.initialize(GA_MEASUREMENT_ID);
  useSentryEmailParam();
  return (
    <QueryClientProvider client={queryClient}>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        :root {
          --font-work-sans: ${workSans.style.fontFamily};
          --font-inter: ${inter.style.fontFamily};
        }`,
        }}
      />
      <GoogleOAuthProvider
        clientId={`${process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}`}
      >
        <div className="flex min-h-screen flex-col text-white caret-secondary-500">
          <Head>
            {createMetaTags({
              title: 'MindPal',
              description:
                'Find top FAANG job opportunities on our job board. Connect with leading tech employers and elevate your career with exclusive listings today',
            })}

            <meta name="theme-color" content="#000000" />
          </Head>
          <AuthOnlyPage>{getLayout(<Component {...pageProps} />)}</AuthOnlyPage>
        </div>
        <Toaster
          pauseOnFocusLoss
          toastOptions={toastConfig}
          containerClassName="!top-0 !bottom-0 !left-0 !right-0 justify-normal"
          shouldStack
        >
          {(t) => (
            <ToastBar toast={t}>
              {({ icon, message }) => (
                <>
                  <div />
                  <div className=" flex w-fit gap-2 text-center">
                    {icon}
                    {message}
                  </div>
                  {t.type !== 'loading' && (
                    <div className="flex items-center justify-end">
                      <button
                        className="w-fit p-2"
                        type="button"
                        onClick={() => toast.dismiss(t.id)}
                      >
                        <FontAwesomeIcon
                          icon={faXmark}
                          className="text-neutral-700"
                        />
                      </button>
                    </div>
                  )}
                </>
              )}
            </ToastBar>
          )}
        </Toaster>
        <ReactQueryDevtools initialIsOpen={false} />
      </GoogleOAuthProvider>
    </QueryClientProvider>
  );
}
