import { mergeQueryKeys } from '@lukemorales/query-key-factory';
import type {
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';

import { changelog } from './changelog';
import { common } from './common';
import { community } from './community';
import { cvGenerator } from './cvGenerator';
import { externalCandidates } from './externalCandidates';
import { externalOffers } from './externalOffers';
import { jobMatching } from './jobMatching';
import { payments } from './payments';
import { profile } from './profile';
import { settings } from './settings';

export const queries = mergeQueryKeys(
  cvGenerator,
  common,
  profile,
  payments,
  settings,
  jobMatching,
  externalCandidates,
  changelog,
  externalOffers,
  community
);

export type UseQueryFactoryProps<
  T extends { queryFn: (...args: any) => any; queryKey: QueryKey }
> = UseQueryOptions<
  Awaited<ReturnType<T['queryFn']>>,
  unknown,
  Awaited<ReturnType<T['queryFn']>>,
  T['queryKey']
>;

export type UseMutationFactoryProps<
  T extends { queryFn: (...args: any) => any; queryKey: QueryKey }
> = UseMutationOptions<
  Awaited<ReturnType<T['queryFn']>>,
  unknown,
  Awaited<ReturnType<T['queryFn']>>,
  T['queryKey']
>;
