import type { ZodTypeAny } from 'zod';
import { z } from 'zod';

export const asArray = <T extends ZodTypeAny>(type: T) =>
  z
    .any()
    .transform<z.infer<typeof type>[]>((value: any) =>
      Array.isArray(value)
        ? value.map((el) => type.parse(el))
        : [type.parse(value)]
    );
