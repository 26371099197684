import { createQueryKeys } from '@lukemorales/query-key-factory';
import type { PaginationState } from '@tanstack/react-table';

import {
  downloadCV,
  getCvContext,
  getCvContexts,
  getUserCvUsageData,
} from '@/services/cvGenerator';

export const cvGenerator = createQueryKeys('cvGenerator', {
  list: ({
    id,
    search,
    pagination,
  }: {
    search?: string;
    id?: number | number[];
    pagination?: PaginationState;
  }) => ({
    queryKey: [{ search, id }],
    queryFn: ({ pageParam }) =>
      getCvContexts({
        offset:
          // eslint-disable-next-line no-unsafe-optional-chaining
          (pageParam ?? pagination?.pageIndex) * (pagination?.pageSize ?? 10),
        search,
        pageSize: pagination?.pageSize ?? 10,
        id,
      }),
  }),
  detail: (id: number, isDownloadButton?: boolean) => ({
    queryFn: () => getCvContext(id),
    queryKey: [id, isDownloadButton],
    contextQueries: {
      blob: (
        args: Omit<Parameters<typeof downloadCV>[0], 'id' | 'signal'>
      ) => ({
        queryKey: [args],
        queryFn: ({ signal }) => downloadCV({ ...args, id, signal }),
      }),
    },
  }),
  userCvUsage: {
    queryKey: null,
    queryFn: getUserCvUsageData,
  },
});
